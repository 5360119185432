/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background: rgb(1, 6, 22);
  line-height: 1.5;
  font-size: 0.875rem;
  height: 100vh;
}

ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 2rem;
}
h2 {
  font-size: 1.5rem;
}
h3 {
  font-size: 1rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
  color: var(--clr-grey-5);
}
@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.25rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}

main {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
  /* justify-content: center;
  align-content: center; */
}

section {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
  /* margin-top: 5rem;
  margin-bottom: 5rem; */
  /* justify-content: center;
  align-items: center; */
}

@media (max-width: 1000px) {
  section {
    width: 100%;
  }
}
/* 
@media (max-width: 1000px) {
  section {
    width: 85%;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
} */

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2rem;
  margin-top: 2rem;
  color: whitesmoke;
  justify-content: center;
  text-align: center;
}

@media (max-width: 1000px) {
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2rem;
    margin-top: 0.5rem;
    color: whitesmoke;
    justify-content: center;
    text-align: center;
  }
}

.para {
  color: whitesmoke;
  text-align: center;
}

@media (max-width: 1000px) {
  .para {
    color: whitesmoke;
    text-align: start;
  }
}

.map {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 3rem;
  padding: 2rem;
  border-radius: 1.5rem;
  background: white;
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.btcWorldImg {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  height: 6rem;
  width: 6rem;
  margin-left: 2rem;
  border-radius: 9999px;
}

@media (max-width: 1000px) {
  .btcWorldImg {
    display: flex;
    flex-direction: row;
    /* justify-content: center;
    align-items: center; */
    object-fit: cover;
    height: 5rem;
    width: 5rem;
    margin-left: 1rem;
    border-radius: 9999px;
  }
}

.paraContainer {
  /* margin-bottom: 0rem; */
  margin-top: 3rem;
}

@media (max-width: 1000px) {
  .paraContainer {
    margin-bottom: 0.5rem;
  }
}

.contact {
  color: whitesmoke;
  margin-top: 1.5rem;
}

.contact:hover {
  color: orange;
}

.donate {
  color: whitesmoke;
  padding-top: 2rem;
}

.donateLink {
  color: whitesmoke;
}

.donateLink:hover {
  color: orange;
}

.loveEmoji {
  color: whitesmoke;
}

.mapContainer {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.modal {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 40%;
  z-index: 50;
  background-color: whitesmoke;
  border: 2px solid whitesmoke;
  /* --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06); */
  /* box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow); */
  overflow-y: auto;
  /* -ms-overflow-style: none;
  scrollbar-width: none; */
}

.MuiDrawer-paperAnchorLeft {
  width: 40%;
  overflow-y: auto;
}

.MuiDrawer-paperAnchorBottom {
  min-height: 90vh;
  height: 90vh;
  max-height: 90vh;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  overflow-y: auto;
}

.modalWrapper {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

/* .MuiDrawer-paperAnchorLeft::-webkit-scrollbar {
  width: 15px;
} */

/* .MuiDrawer-paperAnchorLeft::-webkit-scrollbar-thumb { */
/* display: none; */
/* background-color: rgb(107, 105, 105);
  border-radius: 9999px;
  border: 1px solid whitesmoke; */
/* } */

/* .MuiDrawer-paperAnchorBottom::-webkit-scrollbar {
  width: 15px;
} */

/* .MuiDrawer-paperAnchorBottom::-webkit-scrollbar-thumb {
  display: none;
  background-color: rgb(107, 105, 105);
  border-radius: 9999px;
  border: 1px solid whitesmoke;
} */

.modal::-webkit-scrollbar {
  width: 15px;
}

.modal::-webkit-scrollbar-thumb {
  /* display: none; */
  background-color: rgb(107, 105, 105);
  border-radius: 9999px;
  border: 1px solid whitesmoke;
}

@media (max-width: 1000px) {
  .modal {
    display: flex;
    flex-direction: column;
    position: absolute;
    height: 90%;
    width: 90%;
    left: 5%;
    top: 5%;
    z-index: 50;
    background-color: whitesmoke;
    border: 2px solid whitesmoke;
    overflow-y: auto;
    /* -ms-overflow-style: none;
    scrollbar-width: none; */
  }

  /* .modal::-webkit-scrollbar {
    display: none;
  } */
}

.countryImg {
  height: 15rem;
  width: 100%;
  object-fit: fill;
}

@media (max-width: 1000px) {
  .countryImg {
    height: 10rem;
    width: 100%;
    object-fit: fill;
  }
}

.country {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgb(221, 219, 219);
  padding-left: 2rem;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
}

@media (max-width: 1000px) {
  .country {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgb(221, 219, 219);
    padding-left: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

.optionsContainer {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgb(221, 219, 219);
  padding-left: 4rem;
  padding-right: 4rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  justify-content: space-between;
}

@media (max-width: 1000px) {
  .optionsContainer {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid rgb(221, 219, 219);
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    justify-content: space-between;
  }
}

.Overview:hover {
  color: blue;
  cursor: pointer;
}

.Legal:hover {
  color: blue;
  cursor: pointer;
}

.News:hover {
  color: blue;
  cursor: pointer;
}

.Statistics:hover {
  color: blue;
  cursor: pointer;
}

.Other:hover {
  color: blue;
  cursor: pointer;
}

.exitModal {
  position: absolute;
  cursor: pointer;
  left: 93%;
  top: 0.7%;
  height: 2rem;
  width: 2rem;
}

.exitCross {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: x-large;
}

.exitCross:hover {
  background-color: whitesmoke;
  height: 2rem;
  width: 2rem;
  border-radius: 9999px;
}

@media (max-width: 1000px) {
  .exitModal {
    position: absolute;
    cursor: pointer;
    left: 89%;
    top: 0.7%;
    height: 2rem;
    width: 2rem;
  }
}

.horizontalBarContainer {
  position: absolute;
  cursor: pointer;
  left: 45%;
  top: 0.7%;
  height: 1rem;
  width: 4rem;
}

.horizontalBar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 0.5rem;
  width: 4rem;
  border-radius: 30px;
  background-color: rgb(85, 85, 85);
  border: 1px solid rgb(169, 169, 169);
}

.legalContainer {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgb(221, 219, 219);
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1.5rem;
  padding-bottom: 3rem;
  /* overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none; */
}

/* .legalContainer::-webkit-scrollbar {
  display: none;
} */

.newsContainer {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgb(221, 219, 219);
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1.5rem;
  padding-bottom: 3rem;
  /* overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none; */
}

/* .newsContainer::-webkit-scrollbar {
  display: none;
} */

.overviewContainer {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgb(221, 219, 219);
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1.5rem;
  padding-bottom: 3rem;
  /* overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none; */
}

/* .overviewContainer::-webkit-scrollbar {
  display: none;
} */

.statisticsContainer {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgb(221, 219, 219);
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1.5rem;
  padding-bottom: 3rem;
  /* overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none; */
}

/* .statisticsContainer::-webkit-scrollbar {
  display: none;
} */

.otherContainer {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgb(221, 219, 219);
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1.5rem;
  padding-bottom: 3rem;
  /* overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none; */
}

/* .otherContainer::-webkit-scrollbar {
  display: none;
} */
